.tags-link {
    display: flex;
    flex-wrap: nowrap;
    width: 80%;
}

.tag {
    border-radius: 8px;
    height: 26px;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.04em;

    :global(.MuiChip-label) {
        padding: 0;
    }
}

.tag-small {
    width: 3px;
}

.block {
    display: flex;
    align-items: center;
    height: 32px;
    gap: 4px;
}
