.heading-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 28px;
    padding: 2px 0;
}

.heading {
    padding: 2px 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: #444d60;
    letter-spacing: -0.04em;
}

.title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.04em;
}

.progress {
    display: flex;
    flex-direction: column;
    width: 200px;
    gap: 4px;
    flex-shrink: 0;
}

.responsible {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.edit {
    margin: 0;
    color: #b9bcc3;
}

.field-title {
    color: #a1a6af;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    line-height: 17px;
    letter-spacing: -0.04em;
}

.key-result {
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;
}

.bottom-block {
    display: flex;
    gap: 16px;
}

.deadline-block {
    min-width: 99px;
    flex-shrink: 0;
}

.progress-values {
    display: flex;
    align-items: center;
    color: #a1a6af;
    gap: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.04em;
}
