.info-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.title {
    color: rgba(161, 166, 175, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.04em;
    text-align: left;
    text-wrap: nowrap;
}

.field {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.04em;
    color: rgba(29, 29, 31, 1);
    text-wrap: nowrap;
}
