.container {
    display: flex;
}

.tabs-container {
    & :global(.Mui-selected) {
        color: rgba(99, 35, 237, 1);
    }

    & :global(.MuiTabs-indicator) {
        background-color: rgba(99, 35, 237, 1);
    }

    & :global(.MuiTab-root) {
        padding: 12px 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-transform: none;
        min-width: fit-content;
        min-height: 46px;
        display: flex;
        flex-direction: row;
        align-items: end;
        gap: 12px;
    }

    & :global(.MuiTabs-flexContainer) {
        display: flex;
        gap: 32px;
    }
}
