.root {
    flex-shrink: 0;
}

.drawerPaper {

    :global(.MuiList-root) {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}

.logo {
    padding: 12px 0 12px 13px;
    box-sizing: content-box;
}

.toolbar {
    height: 60px;
}

.link {
    text-decoration: none;
    color: inherit;
}

.icon-wrapper {
    min-width: auto;
    margin-right: 10px;
}

.icon {
    width: 15px;
    height: 15px;
    color: #8c8c8c;
}

.list-item, .list-nested-item {
    padding: 0 16px;
    height: 40px;

    &:global(.active) {
        background-color: #f7f3ff;
        color: #6323ed;

        .icon {
            color: #6323ed;
        }
    }

    :global(.MuiTypography-root) {
        font-size: 14px;
        letter-spacing: -0.04em;
    }
}

.list-nested-item {
    padding-left: 40px;
}

.navigation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}