@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --base-0: hsla(0, 0%, 100%, 1);
    --base-100: hsla(0, 0%, 96%, 1);
    --base-200: hsla(222, 15%, 87%, 1);
    --base-500: hsla(219, 8%, 57%, 1);
    --base-600: hsla(219, 10%, 46%, 1);
    --base-900: hsla(240, 3%, 12%, 1);
    --primary-100: hsla(260, 100%, 98%, 1);
    --primary-200: hsla(261, 79%, 91%, 1);
    --primary-400: hsla(255, 100%, 79%, 1);
    --primary-500: hsla(259, 85%, 63%, 1);
    --primary-600: hsla(259, 85%, 53%, 1);
    --primary-700: hsla(259, 79%, 44%, 1);
    --blue: hsla(204, 100%, 31%, 1);
    --blue-200: hsla(217, 86%, 89%, 1);
    --blue-400: hsla(217, 100%, 50%, 1);
    --blue-600: hsla(244, 79%, 37%, 1);
    --green-100: hsla(140, 44%, 88%, 1);
    --green-200: hsla(140, 43%, 70%, 1);
    --green-300: hsla(140, 64%, 41%, 1);
    --green-500: hsla(140, 64%, 27%, 1);
    --orange-200: hsla(24, 94%, 86%, 1);
    --orange-300: hsla(24, 95%, 59%, 1);
    --orange-400: hsla(24, 66%, 49%, 1);
    --yellow-300: hsla(46, 100%, 78%, 1);
    --yellow-400: hsla(46, 100%, 50%, 1);
    --yellow-500: hsla(46, 100%, 23%, 1);
    --red-200: hsla(6, 80%, 75%, 1);
    --red-300: hsla(6, 80%, 58%, 1);
    --pink-200: hsla(291, 80%, 82%, 1);
    --pink-300: hsla(291, 91%, 47%, 1);
}

:global(html) {
    width: 100%;
    height: 100%;
}

:global(body) {
    width: 100%;
    height: 100%;
    font-family: 'Inter', sans-serif;
}

:global(#root) {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

html, body {
    height: 100%;
}

th[scope="colgroup"][colspan="3"] {
    background-color: var(--primary-100) !important;
    border-left: 1px solid var(--base-200);
    border-right: 1px solid var(--base-200);
    border-bottom: 1px solid var(--base-200) !important;
}

th[datascope="progressEndValueQ3"] {
    color: var(--base-500) !important;
    border-left: 1px solid var(--base-200);
}

th[datascope="progressFactValueQ3"] {
    color: var(--base-500) !important;
}

th[datascope="progressFactValue"] {
    color: var(--base-500) !important;
    border-right: 1px solid var(--base-200);
}
