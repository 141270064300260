.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    background-color: #f8fafc;
    gap: 8px;
}

.back-button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 8px 16px;
    background-color: #fff;
    border-radius: 12px;
    color: #6323ed;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.04em;
    text-transform: none;
}

.back-icon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.icon {
    color: #6323ed;
}

.spinner-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
