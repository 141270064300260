.container {
    width: 100%;
    color: #8a909b;

    :global(.MuiBreadcrumbs-separator) {
        margin: 0;
    }
}

.link {
    text-decoration: none;
    color: #8a909b;
    padding: 0 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.04em;
}
