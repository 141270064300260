.card {
    width: 100%;
    min-height: 240px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: Inter;
    border-radius: 12px;
    gap: 8px;
    box-shadow: none;
}

.heading-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.heading {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.04em;
}

.heading-objective {
    font-size: 20px;
    color: #8a909b;
    font-weight: 400;
    flex-grow: 1;
    line-height: 1;
}

.title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.04em;
}

.field {
    width: 62%;
    text-align: right;
}

.data-fields {
    display: flex;
    gap: 12px;
}

.info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    padding: 12px
}

.children-statuses {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 165px;
    padding: 12px 0;
}

.objective {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60px;
}

.objectives {
    display: flex;
    flex-direction: column;
    width: 62%;
}

.button {
    color: rgba(0, 0, 0, 0.85);
    padding: 6.4px 15px;
    text-transform: none;
    border-radius: 8px;
    border: 1px dashed rgba(185, 188, 195, 1);
    background: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    text-wrap: nowrap;
}

.dot {
    width: 3px;
    height: 3px;
    background-color: #b9bcc3;
}

.progress-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.button-block {
    display: flex;
    gap: 8px;
}

.line {
    width: 199px;
}

.tags-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.tag-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--base-600);
}

.dates {
    margin-top: 4px;
}
