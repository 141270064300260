.form {
    width: 100%;
    min-height: 240px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: Inter;
    border-radius: 12px;
    background-color: #fff;

    :global(.MuiInputBase-root) {
        height: 34px;
        border-radius: 8px;
        letter-spacing: -0.04em;
        font-size: 14px;
    }
    :global(.MuiOutlinedInput-input) {
        padding: 6.5px 14px 6.5px 11px;
    }
}

.heading {
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.04em;
    padding-bottom: 8px;
}

.title {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.04em;
    line-height: 20px;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    :global(.MuiFormLabel-root) {
        line-height: 18px;
    }
}

.additional-field {
    display: flex;
    flex-direction: column;
    min-width: 287px;
    width: 20%;
    gap: 10px;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.form-control {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.label {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.04em;
    color: rgba(29, 29, 31, 1);
}

.asterisk {
    color: red;
    position: relative;
    top: 5px;
}

.cancel {
    color: rgba(0, 0, 0, 0.85);
    padding: 6.4px 15px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.create {
    background-color: #6323ed;
    color: #fff;
    padding: 6.4px 15px;
    border-radius: 8px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.create:hover {
    color: black;
}

.button:disabled {
    background-color: #f5f5f5;
    color: #b9bcc3;
}

.progress {
    width: 200px;
    height: 22px;
}

.datepicker :global(.MuiInputBase-formControl) {
    height: 34px;
}

.add-tag {
    color: #1d1d1f;
    align-self: flex-start;
    text-transform: none;
    letter-spacing: -0.04em;
}

.cancel-icon {
    padding: 0;
    color: #a1a6af;
}

.chip-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    border-radius: 8px;

    :global(.MuiChip-root) {
        background-color: rgba(245, 245, 245, 1);
    }
}

.option {
    padding-left: 16px;
    border-radius: 8px;
}

.option:hover {
    background-color: #fafafa;
}

.option :global(.Mui-checked) {
    color: #6323ed;
}

.close {
    width: 10px;
    height: 10px;
}

.select-default {
    color: rgba(0, 0, 0, 0.23);
}

.sizes {
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.6);
}

.line {
    height: 4px;
    color: #6323ed;
    padding: 10px 0;
}

.line :global(.MuiSlider-thumb) {
    width: 15px;
    height: 15px;
}

.tags {
    height: 50px;
    margin-top: 10px;

    :global(.MuiButton-startIcon) {
        margin-right: 0;
    }
}

.tags-list {
    display: flex;
    align-items: center;
    gap: 4px;
}

.tag-input {
    width: 200px;
    padding-right: 0;
    background-color: #f5f5f5;
}

.helper {
    color: rgba(161, 166, 175, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.04em;
}

.responsible-block {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.select-form-control {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 88px;
}

.modal {
    flex-direction: column;
}

.cancel-icon {
    color: #6323ed;
}

.cancel-icon-tag {
    color: '#a1a6af';
}

.unit {
    color: #6323ed;
    width: 37px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-option {
    padding: 11.5px 16px;
    border-radius: 8px;
    gap: 8px;
}

.option-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.04em;
    flex-grow: 1;
}

.selected-icon {
    color: #b9bcc3;
}

.metrics {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}