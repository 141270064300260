.empty-result {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    padding: 16px 5px;
    margin-right: 16px;
}

.title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.04em;
    text-align: left;
    padding-bottom: 8px;
}

.button {
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background: #6323ed;
    padding: 3px 15px;
    gap: 10px;
    text-transform: none;

    :global(.MuiButton-startIcon) {
        margin-right: 0;
    }
}
