.root {
    width: 320px;
    min-height: 194px;
    border-radius: 14px;
    position: relative;
    overflow: visible;
    box-shadow: none;

    &[data-hierarchical='0'] {
        --card-header-color: var(--primary-200);
        --card-text-color: var(--primary-700);
        --card-icon-color: var(--primary-600);
    }

    &[data-hierarchical='1'] {
        --card-header-color: var(--green-200);
        --card-text-color: var(--green-500);
        --card-icon-color: var(--green-300);
    }

    &[data-hierarchical='2'] {
        --card-header-color: var(--blue-200);
        --card-text-color: var(--blue-600);
        --card-icon-color: var(--blue-400);
    }

    &[data-hierarchical='3'] {
        --card-header-color: var(--orange-200);
        --card-text-color: var(--orange-400);
        --card-icon-color: var(--orange-300);
    }

    &[data-hierarchical='4'] {
         --card-header-color: var(--yellow-300);
         --card-text-color: var(--yellow-500);
         --card-icon-color: var(--yellow-400);
    }

    &[data-hierarchical='5'] {
        --card-header-color: var(--red-200);
        --card-text-color: white;
        --card-icon-color: var(--red-300);
    }


    &[data-hierarchical='6'] {
        --card-header-color: var(--blue);
        --card-text-color: white;
        --card-icon-color: var(--blue-400);
    }

    &[data-hierarchical='7'] {
        --card-header-color: var(--primary-400);
        --card-text-color: white;
        --card-icon-color: var(--primary-600);
    }

    &[data-hierarchical='8'] {
        --card-header-color: var(--base-200);
        --card-text-color: var(--base-900);
        --card-icon-color: var(--base-600);
    }

    &[data-hierarchical='9'] {
        --card-header-color: var(--pink-200);
        --card-text-color: var(--base-900);
        --card-icon-color: var(--pink-300);
    }

    &[data-hierarchical='10'] {
        --card-header-color: var(--blue-400);
        --card-text-color: var(--base-0);
        --card-icon-color: var(--blue-600);
    }

    &[data-hierarchical='11'] {
        --card-header-color: var(--base-800);
        --card-text-color: var(--base-0);
        --card-icon-color: var(--base-400);
    }

    &[data-hierarchical='12'] {
        --card-header-color: var(--orange-400);
        --card-text-color: var(--base-0);
        --card-icon-color: var(--base-300);
    }

    &[data-hierarchical='13'] {
        --card-header-color: var(--blue-600);
        --card-text-color: var(--base-0);
        --card-icon-color: var(--blue-400);
    }

    :global(.MuiCardHeader-content) {
        flex: 1;
        height: 40px;
    }

    :global(.MuiCardHeader-action) {
        margin: 0;
    }
}

.header {
    gap: 4px;
    padding: 16px 16px 12px 16px;
    border-radius: 14px 14px 0 0;
    overflow: hidden;
    background: white;

    :global(.MuiCardHeader-title) {
        color: #1d1d1f;
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.6px;
    }

    :global(.MuiIconButton-root) {
        padding: 0;
    }
}

.banner {
    border-radius: 12px 12px 0px 0px;
    background: var(--card-header-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 20px 12px;
    color: var(--card-text-color);
    margin-bottom: -16px;
}

.banner-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    height: 17px;
}

.banner-icon,
.banner-big-icon,
.bottom-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--card-icon-color);
    color: #fff;
}

.banner-icon {
    width: 39px;
    height: 27px;
    padding: 4px 6px 4px 6px;
    border-radius: 0 12px 0 6px;

    :global(.MuiSvgIcon-root) {
        width: 20px;
    }
}

.banner-big-icon {
    padding: 4px 6px;
    border-radius: 8px;
    margin-top: 4px;
    margin-right: 4px;
    width: 53px;
    height: 28px;
    &:empty {
        background-color: transparent;
    }
}

.bottom-icon {
    width: 36px;
    height: 28px;
    padding: 8px 12px 8px 12px;
    border-radius: 8px;
    position: absolute;
    bottom: -18px;
    align-self: center;
}

.bottom-icon:hover {
    background-color: var(--card-icon-color);
}

.title {
    color: #b9bcc3;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
    text-align: left;
}

.root-info {
    text-align: left;
    color: #1d1d1f;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.deadline-block {
    display: flex;
    flex-direction: column;
    width: 140px;
}

.deadline-text,
.card-info {
    color: #1d1d1f;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-wrap: nowrap;
    letter-spacing: -0.04em;
}

.card-content {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 6px;
}

.bottom-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 4px 0 12px 0;
    gap: 12px;
}

.button-edit {
    padding: 6px 8px;
    min-width: 0;
    background-color: #F5F5F5;
    border-radius: 4px;
    box-shadow: none;
    color: #a1a6af;
}

.main {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
}

.main:last-child {
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button {
    color: #a1a6af;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    text-transform: uppercase;
    align-self: center;
    padding: 8px 0;
}

.progress {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.arrow-container {
    height: 32px;
    width: 32px;
    background-color: var(--card-text-color);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.progress-values {
    display: flex;
    align-items: center;
    color: #a1a6af;
    gap: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.04em;
    height: 16px;
}

:global(.react-flow__handle) {
    opacity: 0;
    pointer-events: none !important;
}
