.root {
    display: flex;
    min-height: 100%;
    flex: 1;
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #f8fafc;
    overflow: hidden;
}
