.root {
  flex: 1;
}

.background {
  background-color: #f8fafc;
}

.spinner-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
