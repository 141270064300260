.title {
    color: #bfbfbf;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.04em;
}

.block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.status {
    width: 100%;
    height: 22px;
    border-radius: 5px;
    background-color: #f5f5f5;
}

.status span {
    background-color: #ffe58f;
}

.info {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    :global(.MuiTypography-root) {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(29, 29, 31, 1);
    }
}
