.progress-values {
    display: flex;
    align-items: center;
    color: #a1a6af;
    gap: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.04em;
    height: 16px;
}

.unit {
    color: #a1a6af;
    height: 15px;
    width: auto;

    path {
        fill: #a1a6af;
        stroke: #a1a6af;
    }
}

.tenge {
    width: 8px;
}

.line {
    display: flex;
    height: 16px;
    align-items: center;
}
