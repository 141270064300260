.block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    padding-bottom: 16px;
    box-shadow: none;
}

.heading-block {
    width: 100%;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background-color: #f5f5f5;
}

.accordion-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.04em;
    color: rgba(29, 29, 31, 1);
}

.heading-objective {
    color: rgba(68, 77, 96, 1);
    flex-grow: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.1px;
}

.dot {
    width: 3px;
    height: 3px;
    background-color: #b9bcc3;
}

.button {
    color: rgba(105, 113, 128, 1);
    display: flex;
    align-self: flex-end;
    padding: 6.4px 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid rgba(105, 113, 128, 1);
    text-transform: none;

    :global(.MuiButton-startIcon) {
        margin-right: 0px;
    }
}

.main {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    width: 100%;
    padding: 8px 0px 16px 16px;
}
