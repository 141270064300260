.item {
    width: 100%;
    box-shadow: none;

    :global(.MuiAccordionSummary-root) {
        padding-left: 0;
    }

    :global(.MuiAccordionSummary-content) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 9px;
        margin-bottom: 0px;
    }

    :global(.MuiIconButton-root) {
        padding: 0;
        align-self: flex-end;
    }

    :global(.MuiAccordionSummary-expandIconWrapper) {
        align-self: flex-end;
        margin-bottom: 13px;
    }

    :global(.MuiAccordionDetails-root) {
        padding: 8px 0 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    :global(.MuiAccordionSummary-expandIconWrapper.Mui-expanded) svg {
        color: rgba(99, 35, 237, 1);
    }
}

.title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.04em;
}

.check {
    color: #b9bcc3;
}

.additional-field {
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 199px;
}

.data-fields {
    display: flex;
    width: 325px;
    gap: 12px;
}

.edit {
    margin: 0 8px 13px;
    color: #b9bcc3;
}

.field-title {
    color: #a1a6af;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    line-height: 17px;
    letter-spacing: -0.04em;
}
