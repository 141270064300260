.field {
    width: 30%;
    display: flex;
}

.objective {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.04em;
}
