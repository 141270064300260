.container {
    padding-bottom: 20px;
    background-color: #F8FAFC;
    :global(.MuiCard-root) {
        padding: 0;
        padding-top: 5px;

        h5 {
            font-size: 14px;
            line-height: 17px;
        }
    }

    :global(.MuiIconButton-root) {
        padding: 0;
    }

    :global(.MuiChip-root) {
        height: auto;
    }

    :global(.MuiButton-root.Mui-disabled) {
        background-color: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
        border: none;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    gap: 12px;
    border-bottom: 1px solid rgba(217, 220, 227, 1);
}

.header-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: rgba(29, 29, 31, 1);
}

.close-icon {
    width: 20px;
    height: 20px;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title-block {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    background-color: white;
    border-radius: 14px;
}

.heading {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.04em;
    flex-grow: 1;

}

.button-block {
    display: flex;
    align-items: center;
    gap: 10px;

    :global(.MuiIconButton-root) {
        padding: 4px 12px;
        border: 1px dotted rgba(185, 188, 195, 1);
        border-radius: 8px;
        height: 32px;
    }
}

.button {
    color: rgba(105, 113, 128, 1);
    display: flex;
    align-self: flex-end;
    padding: 4px 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid rgba(105, 113, 128, 1);
    text-transform: none;
    width: 186px;
    letter-spacing: -0.04em;

    :global(.MuiButton-startIcon) {
        margin-right: 0px;
    }

    :global(.MuiSvgIcon-root) {
        font-size: 17;
    }
}

.heading-block {
    width: 100%;
    padding: 12px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.accordion-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: rgba(29, 29, 31, 1);
    flex-grow: 1;
}

.icon {
    color: #6323ed;
    width: 24px;
    height: 24px;
}

.result {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.line {
    width: 100%;
    height: 1px;
    background-color: rgba(217, 220, 227, 1);
}

.key-results {
    padding: 0 20px;
}

.items {
    display: flex;
    flex-direction: column;
    gap: 8px;

    form {
        padding: 0 0 16px 0;
        border-radius: 0;
        border-bottom: 1px solid rgba(217, 220, 227, 1);
    }
}

.withLine:not(:last-child)::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(217, 220, 227, 1);
}

.disabled {
    pointer-events: none;

    .button,
    .items {
        opacity: 50%;
    }
}

.main.disabled {
    display: none;
}

.disabled-result {
    opacity: 50%;
}

.empty-block {
    display: flex;
    flex-direction: column;
}

.cancel-icon {
    color: #6323ed;
}
