.container {
    padding: 26px 32px;
    zoom: 0.8;
}

.heading {
    display: flex;
    width: inherit;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.04em;
}

.heading-hierarchy {
    flex-shrink: 0;
}

.heading-icon {
    width: 16px;
    height: 16px;
    color: #FA8232;
}

.heading-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.04em;
}

.progress-values {
    display: flex;
    align-items: center;
    color: #A1A6AF;
    gap: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.04em;
}

.date-text {
    width: inherit;
    max-width: 115px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.04em;
}

.assignee-text {
    width: inherit;
    max-width: 245px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.04em;
}

.buttons-container {
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}

.button-icon {
    width: 24px;
    height: 24px;
    color: #A1A6AF;
}

.tree-icon {
    display: flex;
    align-items: center;
}

.icon-rotate {
    transform: rotate(90deg);
}

.row-icon {
    position: absolute;
    height: 100%;

    &[data-hierarchical='0'] {
        --card-header-color: transparent;
    }

    &[data-hierarchical='1'] {
        --card-header-color: var(--orange-400);
    }

    &[data-hierarchical='2'] {
        --card-header-color: var(--blue-200);
        --card-text-color: var(--blue-600);
        --card-icon-color: var(--blue-400);
    }

    &[data-hierarchical='3'] {
        --card-header-color: var(--orange-200);
        --card-text-color: var(--orange-400);
        --card-icon-color: var(--orange-300);
    }

    &[data-hierarchical='4'] {
        --card-header-color: var(--yellow-300);
        --card-text-color: var(--yellow-500);
        --card-icon-color: var(--yellow-400);
    }

    &[data-hierarchical='5'] {
        --card-header-color: var(--red-200);
        --card-text-color: white;
        --card-icon-color: var(--red-300);
    }


    &[data-hierarchical='6'] {
        --card-header-color: var(--blue);
        --card-text-color: white;
        --card-icon-color: var(--blue-400);
    }

    &[data-hierarchical='7'] {
        --card-header-color: var(--primary-400);
        --card-text-color: white;
        --card-icon-color: var(--primary-600);
    }

    &[data-hierarchical='8'] {
        --card-header-color: var(--base-200);
        --card-text-color: var(--base-900);
        --card-icon-color: var(--base-600);
    }

    &[data-hierarchical='9'] {
        --card-header-color: var(--pink-200);
        --card-text-color: var(--base-900);
        --card-icon-color: var(--pink-300);
    }

    &[data-hierarchical='10'] {
        --card-header-color: var(--blue-400);
        --card-text-color: var(--base-0);
        --card-icon-color: var(--blue-600);
    }

    &[data-hierarchical='11'] {
        --card-header-color: var(--base-800);
        --card-text-color: var(--base-0);
        --card-icon-color: var(--base-400);
    }

    &[data-hierarchical='12'] {
        --card-header-color: var(--orange-400);
        --card-text-color: var(--base-0);
        --card-icon-color: var(--base-300);
    }

    &[data-hierarchical='13'] {
        --card-header-color: var(--blue-600);
        --card-text-color: var(--base-0);
        --card-icon-color: var(--blue-400);
    }
}

.users {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.row {
    vertical-align: baseline;
}

.row-number {
    position: relative;
    display: flex;
}

.row-icon::before {
    content: '';
    position: absolute;
    top: -16px;
    left: -20px;
    width: 4px;
    height: 100%;
    border-radius: 4px 0px 0px 4px;
    background-color: var(--card-header-color);
}

.row-number-item {
    margin-right: 32px;
}

.metric {
    color: var(--primary-500);
}