.zoom-controls {
    box-shadow: 0 20px 30px 0 #0000000F;
    background-color: #FFF;
    padding: 3px;
    box-sizing: border-box;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1000;
}

.zoom-controls__button {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    border: none;
    background: transparent;
    outline: none;
}

.zoom-controls__button:hover {
    background-color: #F5F5F5;
    cursor: pointer;
}
