.root {
  width: 100%;
  min-height: 116px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid #d9dce3;
}

.withContent {
  min-height: unset;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;
}

.heading-block {
  display: flex;
  justify-content: space-between;
}

.heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.04em;
}

.notifications {
  background-color: rgba(225, 212, 250, 1);
  border-radius: 8px;
  padding: 6.4px 8px;
  border: 1px solid rgba(225, 212, 250, 1);

  :global(.MuiSvgIcon-root) {
      color: rgba(99, 35, 237, 1);
      width: 16px;
      height: 16px;
  }
}

.main {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0;
  background-color: #fff;
}

.button {
  color: rgba(99, 35, 237, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: none;
  padding: 4px 15px;
}

.buttons-container {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.empty {
  min-height: 116px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: 'rgba(29, 29, 31, 1)';
  line-height: 24px;
  letter-spacing: -0.04em;
}

.create {
  background-color: #6323ed;
  color: #fff;
  padding: 6.4px 15px;
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.create:hover {
  color: black;
}

.cancel {
  color: rgba(0, 0, 0, 0.85);
  padding: 6.4px 15px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.clear {
  color: #8A909B;
  font-size: 14px;
  text-transform: none;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px 16px 16px;
}

.actions {
  display: flex;
  gap: 8px;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 16px;
}

.field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.field-title {
  color: #1D1D1F;
  font-size: 14px;
  min-width: 120px;
}

.row {
  display: flex;
  flex-grow: 2;
  gap: 8px;

  :global(.MuiAutocomplete-tag) {
      border-radius: 2px;
      border: 1px solid #F0F0F0;
      background-color: #F5F5F5;
  }
}

.datepicker :global(.MuiInputBase-formControl) {
  height: 32px;
}

.additional-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 199px;
}
